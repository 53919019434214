import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {
  BASE_URL_IMAGE,
  END_POINT,
  METHODS,
  NumberSeprator,
  TabBarLabels,
} from "../../Helper/helper";
import { fetchData } from "../../Helper/ApiCall";

import moment from 'moment';


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import StickySearchBar from "../../components/StickySearchBar";
import { Link, useParams, useLocation, useNavigate } from "react-router-dom";
import ActivitySelector from "../../components/ActivitySelector";
import PartnersCarousel from "../../components/PartnersCarousel";
import Subscribe from "../../components/Subscribe";
import { useSelector, useDispatch } from "react-redux";

export const ProductLevel1 = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const { country, currency } = useSelector((store) => store.currencyReducer);
  const { HomeBanner, SectionSecond, RecomendedBlog, SectionForth, loading } =
    useSelector((store) => store.homeReducer);
  const tabBar = location?.state?.tabBar;

  const { city, checkin, checkout, room } = useParams();
  const [dynamicCity, setHotelCityData] = useState([]);
  const [selectedtab, setselectedtab] = useState();
  const [selectroom, setselectroom] = useState();
  const [selectedcity, setselectedcity] = useState();

  const [defaultValuecheckin, setdefaultValuecheckin] = useState();
  const [defaultValuecheckout, setdefaultValuecheckout] = useState();
  const [hotel, setHotelData] = useState([]);
  const minCheckinDate = new Date().toISOString().split("T")[0];
  const [blogcategory, setblogcategory] = useState([]);
  const [navTitle, setnavTitle] = useState();

  const today = new Date();
  const date = today.setDate(today.getDate());
  // const defaultValuecheckin = new Date(date).toISOString().split("T")[0]; // yyyy-mm-dd
  const nextdate = today.setDate(today.getDate() + 1);
  // const defaultValuecheckout = new Date(nextdate).toISOString().split("T")[0]; // yyyy-mm-dd

  const params = useParams();

  const queryParams = new URLSearchParams(location.search);
  const tabFromUrl = queryParams.get("tab");
  const selectedTabFinal = selectedtab || tabFromUrl || "defaultTab";

  useEffect(() => {
    setdefaultValuecheckin(checkin);
    setdefaultValuecheckout(checkout);
    setselectedcity(city);
    setselectroom(room);
    cityHandler();
    setselectedtab(tabBar);
    blogcategoryHandler();
  }, [checkin]);
  useEffect(() => {
    if (selectedtab != undefined) {
      const data =
        HomeBanner != "" &&
        HomeBanner.find((item) => {
          return item.type == selectedtab;
        });
      setnavTitle(data);

      cityHandler();
      hotelHandler(city, checkin, checkout, room);
    }
  }, [selectedtab]);

  const blogcategoryHandler = async () => {
    try {
      const result = await fetchData(END_POINT.GetCategoryDataBlog);
      console.log(result);
      if (result.success == true) {
        setblogcategory(result.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const cityHandler = async () => {
    try {
      const finalEndpoint =
        selectedtab == TabBarLabels.Hotel
          ? END_POINT.GetHotelCity
          : selectedtab == TabBarLabels.Dinning
            ? END_POINT.GetDiningCity
            : selectedtab == TabBarLabels.Activities
              ? END_POINT.GetActivityCity
              : selectedtab == TabBarLabels.Vehicles
                ? END_POINT.GetVehiclesCity
                : selectedtab == TabBarLabels.Packages
                  ? END_POINT.GetPackageCity
                  : null;
      const result = await fetchData(finalEndpoint);
      console.log(result);
      if (result.success == true) {
        // const newArray = result.data.map((item) => {
        //   return { value: item.city_name, label: item.city_name };
        // });
        setHotelCityData(result.data);
        // setdefaultValuecheckin(new Date(date).toISOString().split("T")[0]);
        // setdefaultValuecheckout(new Date(nextdate).toISOString().split("T")[0]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const hotelHandler = async (city, checkin, checkout, room) => {
    try {
      const raw = JSON.stringify({
        city: city,
        checkin: checkin,
        checkout: checkout,
        room: room,
      });
      const finalEndpoint =
        selectedtab == TabBarLabels.Hotel
          ? END_POINT.GetHotelByCity
          : selectedtab == TabBarLabels.Dinning
            ? END_POINT.GetDinningByCity
            : selectedtab == TabBarLabels.Activities
              ? END_POINT.GetActivityByCity
              : selectedtab == TabBarLabels.Vehicles
                ? END_POINT.GetVehiclesByCity
                : selectedtab == TabBarLabels.Packages
                  ? END_POINT.GetPackageByCity
                  : null;
      const result = await fetchData(finalEndpoint, METHODS.POST, raw);

      console.log(raw);
      if (result.success == true) {
        setHotelData(result.data);
      } else {
        setHotelData([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const ButtonDiv = ({ title, img, imgYellow }) => {
    return (
      <li class="nav-item ">
        <a
          class="nav-link tab-item"
          type="button"
          onClick={() => {
            setselectedtab(title);
            setselectedcity();
          }}
        >
          {selectedtab !== title ? (
            <img src={img} className="img-fluid tab-item-img" id="tab1-img" />
          ) : (
            <img
              src={imgYellow}
              className="img-fluid tab-item-img"
              id="tab1-img"
            />
          )}
          {title}
        </a>
      </li>
    );
  };
  const handleCheckinChange = (e) => {
    console.log('called');
    const selectedCheckinDate = new Date(e.target.value);
    const formattedCheckin = selectedCheckinDate.toISOString().split("T")[0];

    console.log("Formatted Check-in Date:", formattedCheckin);

    const nextDay = new Date(selectedCheckinDate);
    nextDay.setDate(selectedCheckinDate.getDate() + 1);
    const formattedNextDay = nextDay.toISOString().split("T")[0];

    setdefaultValuecheckin(e.target.value);

    setdefaultValuecheckout(formattedNextDay);
    console.log(formattedNextDay);
    console.log(selectedtab);

    // Update the URL with the formatted date
    navigate(`/booking/${city}/${formattedCheckin}/${formattedNextDay}/${room}?tab=${selectedtab}`);

  };

  const onChange = (e) => {
    // setHotelData([]);

    switch (e) {
      case "best-seller":
        const newArrayseller = hotel.filter(
          (item) => item.promoType == "Best Seller"
        );
        setHotelData([...newArrayseller]);
        break;
      case "high-price":
        const newArray = hotel.sort((a, b) => b.startFrom - a.startFrom);
        setHotelData([...newArray]);
        break;
      case "low-price":
        const newArrayLow = hotel.sort((a, b) => a.startFrom - b.startFrom);
        setHotelData([...newArrayLow]);
        break;
    }
  };
  return (
    <div>
      {/* Navbar */}
      {/* <Header /> */}
      {/* Navbar phone and tablet bottom */}
      {/* <nav class="sticky-bottom-navbar">
        <a class="nav-link mob-nav-item " href="#">
          <i
            class="fa-solid fa-baht-sign fa-lg"
            style={{ color: "#ffcc00", padding: 5 }}
          ></i>
          THB
        </a>

        <a class="nav-link mob-nav-item" href="#">
          <i
            class="fa-solid fa-bookmark fa-1x"
            style={{ color: "#ffcc00", padding: 5 }}
          ></i>
          Saved
        </a>

        <a class="nav-link mob-nav-item" href="#">
          <img
            src="/assets/img/ChatsCircle.svg"
            style={{ padding: 5 }}
            // width="50"
            // height="50"
          />
          Support
        </a>

        <a class="nav-link mob-nav-item " href="#">
          <i
            class="fa-regular fa-user"
            style={{ color: "#ffcc00", fontSize: 20, padding: 5 }}
          ></i>
          Sign Up
        </a>
      </nav> */}
      {/* <StickySearchBar /> */}
      {/* Banner 1 */}
      <div
        class="product-banner"
        style={{
          backgroundImage: `url(${BASE_URL_IMAGE + navTitle?.image})`,
        }}
      >
        <div class="row">
          <div class="col-lg-6 md-12 sm-12 align-items-center d-flex p-0 bookingbannermobilehotel">
            <p class="product-banner-title">
              {/* Find your next <span>hotel</span> at Bharatchoice */}
              {navTitle?.title}
            </p>
          </div>
          <ActivitySelector
            selectedtab={selectedtab}
            onChange={(text) => {
              setselectedtab(text);
              setdefaultValuecheckin();
              setdefaultValuecheckout();
            }}
            cityData={dynamicCity}
            selectedcity={selectedcity}
            onChangeselectedcity={(text) => {
              setselectedcity(text);
            }}
            defaultValuecheckin={defaultValuecheckin}
            minCheckinDate={minCheckinDate}
            handleCheckinChange={handleCheckinChange}
            defaultValuecheckout={defaultValuecheckout}
            minCheckoutDate={defaultValuecheckin}
            onChangeCheckoutDate={(text) => {
              setdefaultValuecheckout(text);
            }}
            room={selectroom}
            onChangeroom={(text) => {
              setselectroom(text);
            }}
            submitButton={() => {
              hotelHandler(
                selectedcity,
                defaultValuecheckin,
                defaultValuecheckout,
                selectroom
              );
              // navigate(
              //   "/" +
              //     selectedtab +
              //     `/booking/` +
              //     selectedcity.trim() +
              //     "/" +
              //     defaultValuecheckin +
              //     "/" +
              //     defaultValuecheckout +
              //     "/" +
              //     room,
              //   {
              //     state: {
              //       tabBar: selectedtab,
              //     },
              //   }
              // );
            }}
          />
        </div>
      </div>
      {/* Welcome Section */}
      <div className="container welcome-section find-best">
        <h2>
          Find the best {selectedtab} in {selectedcity}
        </h2>
        <p className="intro">
          Explore the finest {selectedtab} in {selectedcity} that elevates your
          holiday
        </p>
        <div class="dropdown">
          <button
            class="btn dropdown-toggle sort-by-title"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Sort by
            <i
              class="fa-solid fa-angle-down"
              style={{ color: "#0A3874", marginLeft: 20 }}
            ></i>
          </button>
          <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <a
              class="dropdown-item"
              type="button"
              onClick={() => {
                // onChange("high-price");
              }}
            >
              Best Match
            </a>
            <a
              class="dropdown-item"
              type="button"
              onClick={() => {
                onChange("best-seller");
              }}
            >
              Best Seller
            </a>

            <a
              class="dropdown-item"
              type="button"
              onClick={() => {
                onChange("high-price");
              }}
            >
              Price High to Low
            </a>
            <a
              class="dropdown-item"
              type="button"
              onClick={() => {
                onChange("low-price");
              }}
            >
              Price Low to High
            </a>
            <a
              class="dropdown-item"
              type="button"
              onClick={() => {
                // onChange("high-price");
              }}
            >
              Near by
            </a>
          </div>
        </div>
        {/* <div className="d-flex align-items-center mt-2 mb-2">
          <span className="sort-by-title">Sort By</span>
          <select class="form-control sort-by" id="inputLocation">
            <option selected>Select</option>
            <option>Best Match</option>
            <option>Best Seller</option>
            <option>Promotion of the Month</option>
            <option>Price High to Low</option>
            <option>Price Low to High</option>

            <option>Near by</option>
          </select>
          <i class="fa-solid fa-angle-down" style={{ color: "#858585" }}></i>
        </div> */}
        <div className="row product-row">
          {hotel.length > 0 ? (
            hotel.map((item, index) => {
              if (moment(defaultValuecheckin).format('dddd') !== item.offDay) {

                return (
                  <div className="col-lg-3 md-5 sm-12 product-cards">
                    <Link
                      style={{ textDecoration: "none" }}
                      to={`/${selectedTabFinal}-booking-details/` + item._id}
                      refresh="true"
                      state={{
                        selectedtab: selectedtab,
                        checkin: checkin,
                        checkout: checkout,
                        room: room,
                      }}
                    >

                      <div
                        style={{
                          display: "flex",
                          // width: "18rem",
                          // height: "12rem",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "1rem",
                        }}
                      >
                        <img
                          src={BASE_URL_IMAGE + item.images}
                          style={{ objectFit: "cover", maxWidth: "100%" }}
                          className="img-fluid product-card-img"
                        />
                      </div>

                      <div className="product-card-content">
                        <div className="product-tags">
                          <span>{item.type}</span>
                          <span>{item.city}</span>
                        </div>
                        <h5 style={{ fontSize: 18 }}>{item.name}</h5>

                        <div className="price d-flex justify-content-between align-items-center">
                          <div className="d-flex align-items-center ">
                            <img src="/assets/img/star.svg" />
                            <span>{item.starRating}</span>
                          </div>

                          <div className="d-flex">
                            <p
                              style={{
                                fontSize: "small",
                                marginTop: 3,
                                marginRight: 5,
                              }}
                            >
                              From
                            </p>
                            <span>
                              {country}{" "}
                              {NumberSeprator(
                                Math.floor(item.startFrom * currency[country])
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            })
          ) : (
            <p>Hotels Not Available</p>
          )}
        </div>
      </div>
      {/* Blogs section */}
      <div className="blogs-wrapper">
        <div className="container blogs-section">
          <div className="blog-text">
            <h2>Travel Inspiration</h2>
            <p className="blog-intro">
              Our travel blogs will transport you to far-off lands, where you
              can indulge in exotic flavors,
              <br /> revel in festivals, and let your mind wander.
            </p>
          </div>

          <div className="row d-flex justify-content-start align-items-center specialrow">
            {RecomendedBlog != "" &&
              RecomendedBlog.map((item) => {
                return (
                  <div className="col-lg-3 md-3 sm-12 events-cards">
                    <Link to={item.type}>
                      <div class="img-container-box">
                        <div>
                          <img
                            // src="/assets/img/destination-wed.jpeg"
                            src={BASE_URL_IMAGE + item.image}
                            class="img-fluid"
                          />
                        </div>
                        <div class="img-container-box-text">
                          <h3>
                            <a style={{ color: "white" }} href="">
                              {item.title}
                            </a>
                          </h3>
                          <p>{item.description}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            {/* <div className="col-lg-3 md-3 sm-12 events-cards">
              <Link to={"wedding"}>
                <div class="img-container-box">
                  <div>
                    <img
                      src="/assets/img/destination-wed.jpeg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="img-container-box-text">
                    <h3>
                      <a style={{ color: "white" }} href="">
                        Destination Wedding
                      </a>
                    </h3>
                    <p>
                      Exquisite Indian Weddings in Thailand with Unforgettable
                      Moments and Luxury Services
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 md-3 sm-12 events-cards">
              <Link to={"events"}>
                <div class="img-container-box">
                  <div>
                    <img src="/assets/img/mice-events.jpeg" class="img-fluid" />
                  </div>

                  <div class="img-container-box-text">
                    <h3>
                      <a style={{ color: "white" }} href="">
                        Local Events
                      </a>
                    </h3>
                    <p>
                      Exciting Events and Festivals in Thailand: Concerts,
                      Parties, and Bollywood Nights
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 md-3 sm-12 events-cards">
              <Link to={"mice-events"}>
                <div class="img-container-box">
                  <div>
                    <img
                      src="/assets/img/special-events-1.jpeg"
                      class="img-fluid"
                    />
                  </div>
                  <div class="img-container-box-text">
                    <h3>
                      <a style={{ color: "white" }} href="">
                        M.I.C.E Business
                      </a>
                    </h3>
                    <p>
                      Exceptional M.I.C.E Services in Thailand: Meetings,
                      Incentives & More
                    </p>
                  </div>
                </div>
              </Link>
            </div> */}
          </div>
        </div>
      </div>

      {/* Special Events Section */}
      {/* <div className="events-wrapper">
        <div className="container events-section">
          <h2>Special events in Thailand</h2>
          <p className="events-intro">
            Unveiling Events in Thailand: Immerse Yourself in a World of
            Captivating <br /> Experiences Curated by Industry Experts to
            Delight and Astonish You.
          </p>
          <div className="row">
            <div className="col-lg-4 md-6 sm-12 events-cards">
              <img src="/assets/img/eventsoverlay1.png" className="img-fluid" />
            </div>
            <div className="col-lg-4 md-6 sm-12 events-cards">
              <img src="/assets/img/eventsoverlay2.png" className="img-fluid" />
            </div>
            <div className="col-lg-4 md-6 sm-12 events-cards">
              <img src="/assets/img/eventsoverlay3.png" className="img-fluid" />
            </div>
          </div>
        </div>
      </div> */}
      {/* Experiences Section */}
      {/* <div className="experience-wrapper">
        <div className="container experience-section">
          <h2>Client Testimonials: Discover Their Experience</h2>

          <div className="row experince-cards-wrapper">
            <div className="col-lg-3 md-6 sm-12 experience-cards m-2">
              <div className="cards-inner-row">
                <div className="w-40">
                  <img src="/assets/img/userimg.png" className="img-fluid" />
                </div>
                <div className="w-40">
                  <img
                    src="/assets/img/Buildingicon.png"
                    className="img-fluid"
                  />
                </div>
              </div>
              <p>
                Bharat Choice will upscale the potential of service providers to
                various establishments and be ready according to the needs of
                Indian Worldwide consumers.
              </p>
              <p>Traveler name</p>
            </div>
            <div className="col-lg-3 md-6 sm-12 experience-cards m-2">
              <div className="cards-inner-row">
                <div className="">
                  <img src="/assets/img/userimg.png" className="img-fluid" />
                </div>
                <div className="">
                  <img
                    src="/assets/img/Buildingicon.png"
                    className="img-fluid"
                  />
                </div>
              </div>
              <p>
                Bharat Choice will upscale the potential of service providers to
                various establishments and be ready according to the needs of
                Indian Worldwide consumers.
              </p>
              <p>Traveler name</p>
            </div>
            <div className="col-lg-3 md-6 sm-12 experience-cards m-2">
              <div className="cards-inner-row">
                <div className="">
                  <img src="/assets/img/userimg.png" className="img-fluid" />
                </div>
                <div className="">
                  <img
                    src="/assets/img/Buildingicon.png"
                    className="img-fluid"
                  />
                </div>
              </div>
              <p>
                Bharat Choice will upscale the potential of service providers to
                various establishments and be ready according to the needs of
                Indian Worldwide consumers.
              </p>
              <p>Traveler name</p>
            </div>
          </div>
          <div className="col-12 text-center mt-3">
            <button class="btn see-more-btn">See More</button>
          </div>
        </div>
      </div> */}
      {/* PartnerShip Section */}
      <PartnersCarousel />
      {/* CTA Section */}
      <Subscribe
        title={
          "Get the scoop on deals and content with our newsletter, don't miss out!"
        }
      />

      {/* Footer */}

      {/* <Footer /> */}
    </div>
  );
};
